

@font-face {
    font-family: 'DB';
    src: url('assets/fonts/dbsax06.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'DB';
    src: url('assets/fonts/dbsax07.ttf') format('truetype');
    font-weight: bolder;
}

@font-face {
    font-family: 'DB';
    src: url('assets/fonts/dbsax33.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'DB';
    src: url('assets/fonts/dbsax36.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DB';
    src: url('assets/fonts/dbsax37.ttf') format('truetype');
    font-weight: bolder;
    font-style: italic;
}

@font-face {
    font-family: 'DB'; /*a name to be used later*/
    src: url('assets/fonts/dbsax03.ttf') format('truetype'); /*URL to font*/
}

@import './styles-variables';
$primary: #ebebeb;
@import 'bootstrap/scss/bootstrap';
//@import "~@angular/material/prebuilt-themes/pink-bluegrey.css";

*:not(.material-icons) {
    font-family: DB, sans-serif !important; 
}

html {
    font-size: 1rem;
    min-height: 100%;
    height: 100%;
}

body {
    height: 100%;
    min-height: 100%;
}

.form-control {
    height: 40px !important;
}

@include media-breakpoint-up(sm) {
html {
    font-size: 1.2rem;
}
}

@include media-breakpoint-up(md) {
html {
    font-size: 1.4rem;
}
}

@include media-breakpoint-up(lg) {
html {
    font-size: 1.6rem;
}
}

@include media-breakpoint-down(sm) {
    .mat-header-row *, .mat-row .mat-cell span:not(.material-icons, .combined), .mat-row .mat-cell input {
        font-size: .65rem !important;
    }
    .static {
        font-size: medium !important;
    }
    mat-expansion-panel * {
        font-size: smaller !important; 
    }
    mat-paginator * {
        font-size: small !important;
    }
}

@include media-breakpoint-up(md) {
    .mat-header-row *, .mat-row .mat-cell span:not(.material-icons, .combined), .mat-row .mat-cell input {
        font-size: .65rem !important;
    }
    .static {
        font-size: xx-large !important;
    }
    mat-expansion-panel * {
        font-size: medium !important; 
    }
    mat-paginator * {
        font-size: small !important;
    }
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

.hide {
    display: none;
}

.btn-black{
    background-color: #000 !important;
    color: #fff;
}

.btn-black:focus {
    color: #fff;
}

.form-holder {
    margin-top: 5%;
    background: rgba(0,0,0,.05);
    border-radius: 10px;
    padding: 2%;
}

.alert {
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    float: right;
}

.break-word {
    white-space: normal;
    word-wrap: break-word;
}

.break-all {
    white-space: normal;
    word-wrap: break-all;
}

.edit-column, .delete-column {
    width: 15px !important;
}

.edit, .delete {
    cursor: pointer;
}

.edit:hover {
    color: green;
}

.delete:hover {
    color: red;
}

.mat-raised-button {
    min-width: 80px;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.mat-form-field-underline {
    /*change color of underline*/
    background-color: darkgray !important;
}

 .mat-focused, .mat-form-field-label {
    /*change color of label*/
    color: darkgray !important;
}

.truncate-cell {
    max-width: 100px; /* feel free to include any other value */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

section {
    display: flex;
    align-content: center;
    align-items: center;
    height: 60px;
}

.flash-row {
    -webkit-animation: flash 1s 0s 1;
    -moz-animation: flash 1s 0s 1;
    -ms-animation: flash 1s 0s 1;
    -o-animation: flash 1s 0s 1;
    animation: flash 1s 0s 1;
}

@-webkit-keyframes flash {
    from { background-color: white; }
    80% { background-color: rgb(236, 0, 22); }
    to { background-color: white; }
}
@-moz-keyframes flash {
    from { background-color: white; }
    80% { background-color: rgb(236, 0, 22); }
    to { background-color: white; }
}
@-ms-keyframes flash {
    from { background-color: white; }
    80% { background-color: rgb(236, 0, 22)een; }
    to { background-color: white; }
}
@-o-keyframes flash {
    from { background-color: white; }
    80% { background-color: rgb(236, 0, 22); }
    to { background-color: white; }
}
@keyframes flash {
    from { background-color: white; }
    80% { background-color: rgb(236, 0, 22); }
    to { background-color: white; }
}

.led-box {
    height: 30px;
    width: 25%;
    margin: 10px 10px;
    float: left;
}
  
.led-box span {
    float: right;
    margin-right:10px;
    line-height:0px
}

.led {
    position: relative;
    width: 12px;
    height: 12px;
    float: right;
    margin-top: -7px;
    border-radius: 50%;
}

.led-green {
    background-color: lightgreen;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #028032 0 -1px 9px, darkgreen 0 2px 6px;
    -webkit-animation: blinkGreen 10s infinite;
    -moz-animation: blinkGreen 10s infinite;
    -ms-animation: blinkGreen 10s infinite;
    -o-animation: blinkGreen 10s infinite;
    animation: blinkGreen 10s infinite;
}

@-webkit-keyframes blinkGreen {
    from { background-color: lightgreen; }
    20% { background-color: darkgreen; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px; }
    to { background-color: lightgreen; }
}
@-moz-keyframes blinkGreen {
    from { background-color: lightgreen; }
    20% { background-color: darkgreen; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px; }
    to { background-color: lightgreen; }
}
@-ms-keyframes blinkGreen {
    from { background-color: lightgreen; }
    20% { background-color: darkgreen; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px; }
    to { background-color: lightgreen; }
}
@-o-keyframes blinkGreen {
    from { background-color: lightgreen; }
    20% { background-color: darkgreen; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px; }
    to { background-color: lightgreen; }
}
@keyframes blinkGreen {
    from { background-color: lightgreen; }
    20% { background-color: darkgreen; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px; }
    to { background-color: lightgreen; }
}