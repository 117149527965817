@use "sass:math";
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    ls: 992px,
    xl: 1200px,
    xxl: 1400px
);
$enable-negative-margins:     true;
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: math.div($spacer, 4),
  2: math.div($spacer, 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 12,
  8: $spacer * 24
)

